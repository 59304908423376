/*
    Quick swipe:
        - if swiped small amount within 1 sec and released, go to next slide.
        - Swiping longer then 1 sec, only go next after dragged certain amount of px


    mode.fill: 
        overwritten by mode.centered
        overwritten by mode.infinite
*/

import { Rect } from './../../helpers/rect';
import * as easing from './../../helpers/ease-adv';
import bezier from './../../helpers/bezier-easing';
import AF from './../../utils/AF';

import Events from './_events';
import Dots from './_dots';
import Controls from './_controls';
import Animation from './_animation';
import Slides from './_slides';

export default class OpSlider {
    index = 0;
    x = 0;
    xMin = 0;
    xMax = 0;
    indexMax = null;

    constructor(config) {
        this.slider = config.slider;
        this.list = config.list;
        this.frame = config.frame;
        this.rect = this.frame.getBoundingClientRect();
        this.length = config.slides.length;
        this.mode = {
            centered: config.mode.centered || false,
            infinite: config.mode.infinite || false,
            multiple: config.mode.multiple || false,
            updateDotsOnDrag: config.mode.updateDotsOnDrag || false,
            fill: config.mode.centered || config.mode.infinite ? false : config.mode.fill || false,
        };

        this.af = AF.instance();

        this.onResize = this.onResize.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onUp = this.onUp.bind(this);
        this.tick = this.tick.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.select = this.select.bind(this);

        this.events = new Events({
            frame: this.frame,
            list: this.list,
            onDown: this.onDown,
            onMove: this.onMove,
            onUp: this.onUp,
            onResize: this.onResize,
        });

        this.dots = new Dots({
            el: config.dots,
            length: this.length,
            onClick: this.select
        });

        this.controls = new Controls({
            slider: this.slider,
            nextItems: config.nextItems,
            prevItems: config.prevItems,
            next: this.next,
            prev: this.prev,
        });

        this.slides = new Slides({
            parent: this.list,
            els: config.slides,
            mode: this.mode
        });

        this.animation = new Animation(this.list);

        this.af.onNextRead(this.tick);
        this.select(this.index, true);
    }

    onResize() {
        let scope = this;
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(function() {
            scope.af.onNextRead(scope.tick);
            scope.select(scope.index);
        }, 250);
    }

    tick() {
        this.rect = this.frame.getBoundingClientRect();
        this.slides.tick(this.rect.width);
        this.xMin = this._calcX(this.length - 1);
        this.xMax = this._calcX(0);
    }

    _calcX(index) {
        const { slides: s, rect: r } = this;
        let x = 0,
            xFillFrame = r.width - s.widthSum;

        if (this.mode.centered) {
            x = r.width/2 - s.rects[index].width/2;
        }

        if (this.mode.infinite) {
            x -= this.slides.clones.left.width;
        }

        for (let i = 0; i < index; i++) {
            x -= s.rects[i].width;

            if (this.mode.fill && x < xFillFrame) {
                x = xFillFrame;
                this.indexMax = i + 1;
                break;
            }
        }

        return x;
    }

    onDown() {
    }

    onMove() {
        const { events: e, length: l } = this;
        let x = e.mouse.xDist + this._calcX(this.index);
        this.x = this._resist(x);
        this.animation.drag(this.x);

        if (this.mode.updateDotsOnDrag) {
            let index = this.slides.closestSlide(e.mouse.xDist, this.index, e.mouse.xDist > 0);

            if (index < 0){
                index = l + index;
            } else if (index > l - 1) {
                index = index - l;
            }

            this.dots.select(index);
        }
    }

    _resist(x) {
        if (this.mode.infinite) return x;
        if (x > this.xMax) {
            return this.xMax + Math.sqrt(Math.abs(x - this.xMax)) * 4;
        } else if (x < this.xMin) {
            return this.xMin - Math.sqrt(Math.abs(x - this.xMin)) * 4;
        } else {
            return x;
        }
    }

    onUp() {
        if (this.events.mouse.xDist > 50) {
            this.prev(true);
        } else if (this.events.mouse.xDist < -50) {
            this.next(true);
        } else {
            this.select(this.index);
        }
    }

    prev(dragged = false) {
        let index = this.index - 1;

        if (this.mode.multiple & dragged) {
            index = this.slides.closestSlide(this.events.mouse.xDist, this.index, true);
        }

        if (this.mode.infinite) {
            if (index < 0){
                index = this.length - Math.abs(index % this.length);
                this.animation.offset(this.slides.widthSum);
            }
        } else index = Math.max(index, 0);

        this.select(index);
    }

    next(dragged = false) {
        let index = this.index + 1;

        if (this.mode.multiple & dragged) {
            index = this.slides.closestSlide(this.events.mouse.xDist, this.index, false);
        }

        if (this.mode.infinite) {
            if (index > this.length - 1){
                index = index % this.length;
                this.animation.offset(-this.slides.widthSum);
            }
        } else index = Math.min(index, this.length - 1);
    
        this.select(index);
    }

    select(index, instant = false) {
        this.af.onNextWrite(() => {
            this.index = index;

            if (this.mode.fill) {
                this.index = Math.min(index, this.indexMax);
            }

            this.x = this._calcX(this.index);

            this.dots.select(this.index);
            this.slides.select(this.index);

            if (!this.mode.infinite) {
                let showNext = this.index < this.length - 1, 
                    showPrev = this.index > 0;
                this.controls.update(showNext, showPrev);
            }

            if (instant) this.animation.jump(this.x);
            else this.animation.release(this.x);
        });
    }
}