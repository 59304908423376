export const PAGE_SELECTOR = '.page';
// export const HEADER_ELEMENT_SELECTOR = '.header__content';
export const NAV_TOP = '.nav--top';
export const NAV_SLIDE = '.nav-slide';
export const HEADER_MENU = '.nav-menu';
export const ANIMATED_NAV_SLIDE_SECTIONS = '.nav-slide__wrap__section, .nav-slide__wrap';
export const ACTIVE_CLASS = 'active';
export const NAV_SLIDE_HEADING = '.nav-slide h2';
export const JS_LINK_ACTIVE="[data-js-active]";
export const FOOTER = '.footer';
export const LOGO = '.logo';