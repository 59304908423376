import AF from '../utils/AF'
import {distance} from '../utils/math'

class Draw{

    mouse = {x: 0, y: 0}
    lastMouse = []
    isMouseDown = true
    scrollTop = 0
    resolutionDivisor = 2.5
    lineWidth = 0

    constructor(){
        this.drawCanvas = document.getElementById('draw-canvas') // canvas user sees
        this.drawCtx = this.drawCanvas.getContext('2d', { alpha:true})

        this.tempCanvas = document.createElement('canvas') // canvas for main line
        this.tempCtx = this.tempCanvas.getContext('2d')

        this.tempCanvasLineStart = document.createElement('canvas') // canvas for connection between main line and mouse (start of line)
        this.tempCtxLineStart = this.tempCanvasLineStart.getContext('2d')

        this.gradientCanvas = document.getElementById('gradient-canvas') // canvas for the fading out gradient
        this.gradientCtx= this.gradientCanvas.getContext('2d')

        const docWidth = document.documentElement.clientWidth || document.body.clientWidth;

        this.drawCanvas.width = this.tempCanvas.width = this.tempCanvasLineStart.width = this.gradientCanvas.width = this.ww = Math.floor(docWidth/this.resolutionDivisor)
        this.drawCanvas.height = this.tempCanvas.height = this.tempCanvasLineStart.height = this.gradientCanvas.height = this.wh = Math.floor(window.innerHeight/this.resolutionDivisor)

        // colors for masked gradient paintbrush
        this.color1 = {h: 193, s: 72, l: 73} // blue
        this.color2 = {h: 348, s: 69, l: 81} //pink
        this.color3 = {h: 143, s: 68, l: 81} // green
        this.color4 = {h: 49, s: 91, l: 73} // yellow

        // colors for initial gradient overlay fade out
        this.overlayColor1 = {h: 193, s: 72, l: 78} // blue
        this.overlayColor2 = {h: 348, s: 69, l: 86} //pink

        // set variables to defaults
        this.mouseActive = false
        this.alternate = true
        this.lastMoved = null
        this.counter = 0
        this.lastFrame = window.performance.now() * 0.001
        this.timeAtLoad = window.performance.now() * 0.001
        this.tween = false
        this.delayBeforeTweening = 2

        window.addEventListener('resize', this.resize)
        window.addEventListener('mousemove', this.mouseMove)
        window.addEventListener('scroll', this.scroll)

        this.af = new AF()
        this.af.addRead(this.read)
        this.af.addWrite(this.render)


        this.logo = document.querySelector('.logo')
        this.hasVisitedBefore = document.cookie.split(';').filter((item) => item.includes('session=true')).length

        if (!this.hasVisitedBefore) {
            // if new session, so colour fade is happening
            const logoImage = this.logo.getElementsByTagName("img")[0]
            logoImage.addEventListener("load", this.colorFade)
            if (logoImage.complete) {
                this.colorFade()
            }
        }else{
            // otherwise make tween = true
            this.tween = true
        }
    }

    drawLine = (time) => {
        const { tempCtx: tctx, tempCtxLineStart: t2ctx, ww, wh, lastMouse, mouse, alternate } = this

        // set lastMouse positions array if not yet set
        if(lastMouse.length == 0) {
            lastMouse.push(Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse), Object.assign({}, mouse))
        }

        // clear t2ctx (tempCtxLineStart) each frame
        t2ctx.clearRect( 0, 0, ww, wh )

        // update lastMouse pos
        lastMouse.shift()
        if(this.mouseActive){
            lastMouse.push(Object.assign({}, mouse))
        }else{
            lastMouse.push(undefined)
        }

        if(this.mouseActive){
            for(let i = 0; i < lastMouse.length; i++){
                if(!alternate && i == lastMouse.length - 1 && lastMouse[i-3] !== undefined && lastMouse[i-2] !== undefined && lastMouse[i-1] !== undefined && lastMouse[i] !== undefined){
                    // draw very start of line connected to mouse on t2ctx (tempCtxLineStart)
                    const startIndexOffset1 = 3
                    const startIndexOffset2 = 1
                    const endIndexOffset1 = 2
                    const controlIndexOffset = 1
                    this.drawPathWithStroke(t2ctx, lastMouse, i, time, startIndexOffset1, startIndexOffset2, endIndexOffset1, controlIndexOffset)
                }
                if(alternate && i % 2 == 0 && lastMouse[i-4] !== undefined && lastMouse[i-2] !== undefined && lastMouse[i] !== undefined){
                    // draw lines on tctx
                    const startIndexOffset1 = 4
                    const startIndexOffset2 = 2
                    const endIndexOffset1 = 2
                    const controlIndexOffset = 2
                    this.drawPathWithStroke(tctx, lastMouse, i, time, startIndexOffset1, startIndexOffset2, endIndexOffset1, controlIndexOffset)
                }
            }
        }
    }
    
    drawPathWithStroke = (tctx, lastMouse, i, time, startIndexOffset1, startIndexOffset2, endIndexOffset1, controlIndexOffset) => {
        // stroke
        tctx.beginPath()
        
        // coordinates for start, end, and quadratic curve target
        const curveStart = {
            x: (lastMouse[i-startIndexOffset1].x + lastMouse[i-startIndexOffset2].x) * 0.5,
            y: (lastMouse[i-startIndexOffset1].y + lastMouse[i-startIndexOffset2].y) * 0.5
        }
        
        const curveEnd = {
            x: (lastMouse[i-endIndexOffset1].x + lastMouse[i].x) * 0.5,
            y: (lastMouse[i-endIndexOffset1].y + lastMouse[i].y) * 0.5
        }

        const controlTarget = {
            x: lastMouse[i-controlIndexOffset].x,
            y: lastMouse[i-controlIndexOffset].y
        }

        // move and draw
        tctx.moveTo(curveStart.x, curveStart.y)
        tctx.quadraticCurveTo(controlTarget.x, controlTarget.y, curveEnd.x, curveEnd.y)

        // lineWidth
        const targetLineWidth = Math.min(Math.ceil(distance(curveStart, curveEnd) * 0.25), 5)
        const lineWidth = (targetLineWidth + this.oldLineWidth) * 0.5
        
        // add stroke
        tctx.strokeStyle = '#000'
        tctx.lineWidth = lineWidth
        tctx.lineCap = 'round';
        tctx.stroke()

        tctx.closePath()

        this.oldLineWidth = targetLineWidth
    }

    drawScene = (time, counter) => {
        const { drawCtx: ctx, tempCanvas, tempCtx, tempCanvasLineStart, ww, wh, delayBeforeTweening, tween, color1, color2, color3, color4 } = this

        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, ww, wh)

        ctx.globalCompositeOperation = 'destination-out'

        // draw start of line from other canvas
        ctx.drawImage(tempCanvasLineStart, 0,0)

        // draw main line from other canvas
        if(counter % 3 === 0){
            const originalImageData = tempCtx.getImageData(0, 0, ww, wh)
            const processedImageData = this.blur(originalImageData, originalImageData)
            if(processedImageData !== undefined) tempCtx.putImageData(processedImageData, 0, 0)
        }

        ctx.drawImage(tempCanvas, 0,0)

        ctx.globalCompositeOperation = 'destination-over'

        // draw gradient behind

        // progress of gradient tween
        const progress = tween && (time > delayBeforeTweening) ? (Math.sin((time - delayBeforeTweening - Math.PI) * 0.5) + 1) * 0.5 : 0
        
        const gradientBehind = this.createGradient(ctx, color1, color2, color3, color4, progress)

        // fill screen with gradient (destination-over: fill behind)
        ctx.fillStyle = gradientBehind
        ctx.fillRect(0, 0, ww, wh)

        ctx.globalCompositeOperation = 'source-over'
    }

    drawOverlayGradient = () => {
        const { gradientCtx, ww, wh, overlayColor1, overlayColor2, color3, color4 } = this

        const progress = 0

        // fill gradientCtx with gradient
        const gradientOverlay = this.createGradient(gradientCtx, overlayColor1, overlayColor2, color3, color4, progress)
        gradientCtx.fillStyle = gradientOverlay
        gradientCtx.fillRect(0, 0, ww, wh)
    }

    createGradient = (ctx, color1, color2, color3, color4, progress1, progress2 = progress1) => {
        const { ww, wh } = this

        // draw gradient behind
        const gradient = ctx.createLinearGradient(0, 0, ww, wh)

        // mix between color1 and color3, depending on progress1
        const mixedColor1 = {
            h: this.mix(color1.h, color3.h, progress1),
            s: this.mix(color1.s, color3.s, progress1),
            l: this.mix(color1.l, color3.l, progress1)
        }

        // mix between color2 and color 4, depending on progress2
        const mixedColor2 = {
            h: this.mix(color2.h, color4.h, progress2),
            s: this.mix(color2.s, color4.s, progress2),
            l: this.mix(color2.l, color4.l, progress2)
        }

        // colors to output for gradient
        const finalColor1 = `hsl(${Math.round(mixedColor1.h)}, ${Math.round(mixedColor1.s)}%, ${Math.round(mixedColor1.l)}%)`
        const finalColor2 = `hsl(${Math.round(mixedColor2.h)}, ${Math.round(mixedColor2.s)}%, ${Math.round(mixedColor2.l)}%)`

        // add colors to gradient
        gradient.addColorStop(0, finalColor1)
        gradient.addColorStop(1, finalColor2)

        return gradient
    }

    // short function to mix two values based on progress
    mix = (val1, val2, progress) => {
        let mixedVal = val1 * (1 - progress) + val2 * progress

        return mixedVal
    }

    blur = (originalImageData, newImageData) => {
        const { ww } = this
        const originalData = originalImageData.data
        const newData = newImageData.data

        for(let i = 0; i < originalData.length; i += 4){

            const pixel = originalData[i+3]

            const groupLeftStartId = i - 4
            const pixelLeftExists = groupLeftStartId >= 0
            const pixelLeft = pixelLeftExists ? originalData[groupLeftStartId+3] : 0

            const groupRightStartId = i + 4
            const pixelRightExists = groupRightStartId + 4 <= originalData.length
            const pixelRight = pixelRightExists ? originalData[groupRightStartId+3] : 0

            const groupAboveStartId = i - (ww * 4)
            const pixelAboveExists = groupAboveStartId >= 0
            const pixelAbove = pixelAboveExists ? originalData[groupAboveStartId+3] : 0

            const groupBelowStartId = i + (ww * 4)
            const pixelBelowExists = groupBelowStartId + 4 <= originalData.length
            const pixelBelow = pixelBelowExists ? originalData[groupBelowStartId+3] : 0

            const totalValues = pixel + pixelLeft + pixelRight + pixelAbove + pixelBelow
            
            const mixed = totalValues * 0.2 // should be divided by pixelGroupCount, but this was causing lag in Safari

            const intensityDifference = mixed - pixel // positive = brighter, negative = dimmer

            const brightenRate = 6
            const decayRate = 0.5
            const addedIntensity = intensityDifference > 2 ? brightenRate : -decayRate
            const decayedAlpha =  mixed + addedIntensity

            newData[i] = 255
            newData[i+1] = 255
            newData[i+2] = 255
            newData[i+3] = decayedAlpha < 17 ? 0 : decayedAlpha
        }

        return newImageData
    }

    colorFade = () => {
        this.tween = true
        this.delayBeforeTweening = Math.ceil(window.performance.now()*0.001 - this.timeAtLoad + 6)

        const logoImage = this.logo.getElementsByTagName("img")[0]
        logoImage.removeEventListener("load", this.colorFade)
    }

    mouseMove = (e) => {
        const {clientX: x, clientY: y} = e

        const mouseY = y + this.scrollTop

        this.mouse = { x: x/this.resolutionDivisor, y: mouseY/this.resolutionDivisor }

        if(!this.mouseActive) this.mouseActive = true

        this.lastMoved = window.performance.now() / 1000
    }

    scroll = () => {
        const {x, y} = this.mouse

        const differenceSinceLastScroll = window.pageYOffset - this.scrollTop

        const mouseY = y + differenceSinceLastScroll/this.resolutionDivisor

        this.mouse = { x: x, y: mouseY }

        if(!this.mouseActive) this.mouseActive = true

        this.lastMoved = window.performance.now() / 1000
    }

    resize = () => {
        const {innerHeight: h} = window
        const docWidth = document.documentElement.clientWidth || document.body.clientWidth;

        this.drawCanvas.width = this.tempCanvas.width = this.tempCanvasLineStart.width = this.gradientCanvas.width = this.ww = Math.floor(docWidth/this.resolutionDivisor)
        this.drawCanvas.height = this.tempCanvas.height = this.tempCanvasLineStart.height = this.gradientCanvas.height = this.wh = Math.floor(h/this.resolutionDivisor)
    }

    read = () => {
        this.scrollTop = window.pageYOffset;
    }

    render = () => {
        const {scrollTop, ww, wh, ctx, tempCtx, drawCtx, gradientCtx, timeAtLoad, tween, delayBeforeTweening} = this
        
        const time = window.performance.now() * 0.001 - timeAtLoad       
        
        if(!tween || (tween && time < delayBeforeTweening)) this.drawOverlayGradient()

        if(scrollTop < wh*this.resolutionDivisor && ww*this.resolutionDivisor >= 850){
            this.counter++

            if(this.counter === 100) this.counter = 0

            this.drawLine(time)
            this.drawScene(time, this.counter)
            
            // switch alternate back and forth between true and false each frame
            this.alternate = !this.alternate

            if(time - this.lastMoved > 0.1){
                this.mouseActive = false;
            }

            // if the frame rate is really low (i.e. the user is not viewing the canvas at the moment)
            if(time - this.lastFrame > 1){
                if(ctx) ctx.clearRect(0, 0, ww, wh)
                if(tempCtx) tempCtx.clearRect(0, 0, ww, wh)
                if(drawCtx) drawCtx.clearRect(0, 0, ww, wh)
                if(gradientCtx) gradientCtx.clearRect(0, 0, ww, wh)
            }

            this.lastFrame = time;
        }        
    }
}

export default Draw