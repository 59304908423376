import '../scss/main.scss';
import "element-closest"
import "mutation-observer-inner-html-shim"

import * as OpSlider from './components/opSlider';

import MenuToggle from './components/menu';
import HoverItems from './components/HoverItems';
import HoverMenu from './components/HoverMenu';
import Gallery from './components/Gallery';
import ArtworksLayout from './components/ArtworksLayout';
import { Application } from "stimulus";
import App from './App';
import { CollectionsController, ContactController, SocialController, SliderController, NewsletterController} from "./controllers"

new App();
new MenuToggle();
new HoverItems();
new HoverMenu();
new Gallery();
new ArtworksLayout();

const application = Application.start()
application.register("collections", CollectionsController);
application.register("contact", ContactController);
application.register("social", SocialController);
application.register("slider", SliderController);
application.register("newsletter", NewsletterController);

function run() {
    OpSlider.init();
}

window.addEventListener("load", run);
