export default class HoverItems{

    item = [];

    constructor(){
        this.item = [].slice.call(document.querySelectorAll('[hover-menu]'));

        this.hideOtherItems = this.hideOtherItems.bind(this);
        this.showAllItems = this.showAllItems.bind(this);

        for (var i = this.item.length - 1; i >= 0; i--) {
            this.item[i].addEventListener('mouseenter', this.hideOtherItems);
            this.item[i].addEventListener('mouseleave', this.showAllItems);
        }
    }

    hideOtherItems(e){
        this.showAllItems();

        const {color, index} = e.target.dataset;

        for (let i = this.item.length - 1; i >= 0; i--) {
            const distanceFromActive = Math.abs(index - i);

            this.item[i].classList.add(`u--hover--bg-${color}`);
            this.item[i].classList.add(`u--hover--distance-${distanceFromActive}`);
        }
    }

    showAllItems(){
        for (let i = this.item.length - 1; i >= 0; i--) {
            const classList = this.item[i].classList;
            for(let j = classList.length - 1; j > 0; j--){
                if(classList[j].startsWith("u--hover--")){
                    classList.remove(classList[j]);
                }
            }
        }
    }

}
