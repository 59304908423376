import {Controller} from "stimulus"
import Validator from "validator";

export default class SocialController extends Controller {

    static targets = [ 'shareLink' ];

    connect() {

    }

    sharePage(e) {
        e.preventDefault();
        window.open(e.srcElement.getAttribute('data-href'), '', 'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    }

}