const CLASS_SLIDE_ACTIVE = 'is-slide-active';
const CLASS_SLIDE_CLONE = 'is-cloned';

export default class Slides {
    initialised = false;
    widthSum = 0;
    clones = {
        left: {
            width: 0,
            length: 0,
            lengthOld: 0,
            arr: [],
        },
        right: {
            width: 0,
            length: 0,
            lengthOld: 0,
            arr: [],
        }
    };

    constructor(config) {
        if (typeof config.els === 'undefined') return;
        this.initialised = true;
        this.parent = config.parent;
        this.els = config.els;
        this.mode = config.mode;
        this.length = config.els.length;
        this.rects = Array(this.length).fill(null);
        this.select = this.select.bind(this);
        this.tick = this.tick.bind(this);

        for (let i = 0; i < this.length; i++) {
            this.els[i].setAttribute('index', i);
        }
    }

    select(index) {
        if (!this.initialised || !this.els.length) return;
        for (let i = 0; i < this.length; i++) {
            this.els[i].classList.remove(CLASS_SLIDE_ACTIVE);
        }
        this.els[index].classList.add(CLASS_SLIDE_ACTIVE);
    }

    tick(frameWidth) {
        this.widthSum = 0;
        for (let i = 0; i < this.length; i++) {
            this.rects[i] = this.els[i].getBoundingClientRect();
            this.widthSum += this.rects[i].width;
        }

        if (this.mode.infinite) {
            this._updateClones(this.clones.left, true, frameWidth);
            this._updateClones(this.clones.right, false, frameWidth);
        }
    }

    closestSlide(xDist, indexFrom, reverse) {
        let widthSlides = 0,
            width = Math.abs(xDist),
            index = indexFrom,
            length = 0,
            indexNew = indexFrom;

        while (width > widthSlides) {
            let prev = this.rects[index - 1] ? this.rects[index - 1].width * 1/3 : 0;

            widthSlides += this.rects[index].width * 2/3 + prev;
            length += 1;

            if (reverse) {

                index--;
                indexNew--;

                index = index < 0 ? this.length - 1 : index;
            } else {

                index++;
                indexNew++;
                index = index > this.length - 1 ? 0 : index;
            }
        }

        return indexNew;
    }

    _updateClones(side, reverse, frameWidth) {
        let index = reverse ? this.length - 1 : 0;
        let widthVisible = frameWidth;

        side.lengthOld = side.length;
        side.width = 0;
        side.length = 0;

        if (this.mode.centered) widthVisible += frameWidth/2;

        // Find out how many slides needed to fill widthVisible
        while (widthVisible > side.width) {
            side.width += this.rects[index].width;

            if (typeof side.arr[side.length] === 'undefined') {
                side.arr[side.length] = this._createClone(this.els[index]);
            }

            side.length += 1;

            if (reverse) {
                index--;
                index = index < 0 ? this.length - 1 : index;
            } else {
                index++;
                index = index > this.length - 1 ? 0 : index;
            }
        }

        this._refreshClones(side, reverse);
    }

    _createClone(el) {
        let clone = el.cloneNode(true);
        clone.classList.add(CLASS_SLIDE_CLONE);

        return {
            node: clone,
            inTheDom: false
        };
    }

    // Refreshing the DOM
    _refreshClones(side, reverse) {
        for (let i = 0; i < side.arr.length; i++) {
            if (i >= side.length) {
                if (side.arr[i].inTheDom) {
                    side.arr[i].inTheDom = false;
                    this.parent.removeChild(side.arr[i].node);
                }
            } else {
                if (!side.arr[i].inTheDom) {
                    side.arr[i].inTheDom = true;
                    if (reverse) this.parent.insertBefore(side.arr[i].node, this.parent.firstChild);
                    else this.parent.appendChild(side.arr[i].node);
                }
            }
        }
    }
}
