import CancelablePromise from "../utils/CancellablePromise";

class Ajax{
    _instance;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor(){

    }


    load(url, within = null){
        console.log('request '+url);
        return new CancelablePromise((resolve, reject)=>{
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function(e){
                if (this.readyState === 4 && this.status === 200) {

                    if(within){
                        let el = document.createElement('div');
                            el.innerHTML = this.responseText;
                        let data = el.querySelector(within);
                        if(data) {
                            resolve(data.innerHTML);
                        } else {
                            resolve(this.responseText);
                        }


                    }else resolve(this.responseText);


                };//else reject(this.readyState);
            };
            xhttp.open("GET", url, true);
            xhttp.setRequestHeader('X-Requested-With','XMLHttpRequest');
            xhttp.send();
        })

    }

    get(url) {
        return new Promise((resolve, reject)=>{
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function(e){
                if (this.readyState === 4 && this.status === 200) {
                    console.log(this.responseText);
                    return resolve(JSON.parse(this.responseText));
                } else {
                    return reject();
                }
            };
            xhttp.open("GET", url, true);
            xhttp.setRequestHeader('X-Requested-With','XMLHttpRequest');
            xhttp.send();
        })
    }

}

export default Ajax;