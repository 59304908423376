import createHistory from 'history/createBrowserHistory';

class State{

    _instance;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    _onChange = ()=>{};
    lastLocation;
    fromMenu = false;
    changeFns = [];

    constructor(){
        this.history = createHistory();
        this.location = this.history.location;
        this.lastLocation = this.location;

        this.unlisten = this.history.listen((location, action) => {
            this.lastLocation = this.location;
            this.location = location;
            this.changeFns.forEach( fn => fn(location, action) );
        })
    }

    get currentPath(){
        return this.location.pathname;
    }

    change({title = '', page = '', type = '', data = {} }){
        if(type === 'menu') this.fromMenu = true;
        else this.fromMenu = false;
        this.history.push(page, { title: title, data })
    }

    onChange(fn){
        this.changeFns.push(fn);

    }
}

export default State;