export default class HoverItems{

    item = [];

    constructor(){

        this.item = [].slice.call(document.querySelectorAll('[hover-item]'));


        this.hideOtherItems = this.hideOtherItems.bind(this);
        this.showAllItems = this.showAllItems.bind(this);

        for (var i = this.item.length - 1; i >= 0; i--) {
            this.item[i].addEventListener('mouseenter', this.hideOtherItems);
            this.item[i].addEventListener('mouseleave', this.showAllItems);
        }

    }

    hideOtherItems(e){

        const {hoverId} = e.target.dataset;

        for (var i = this.item.length - 1; i >= 0; i--) {
            if(this.item[i].dataset.hoverId === hoverId){
                this.item[i].classList.remove('hover-show');
                this.item[i].classList.add('hover-hide');
            }
        }

        e.target.classList.remove('hover-hide');
        e.target.classList.add('hover-show');
    }

    showAllItems(e){
        for (var i = this.item.length - 1; i >= 0; i--) {
            this.item[i].classList.remove('hover-hide');
            this.item[i].classList.add('hover-show');
        }
    }

}
