export function getX(e) {
    if (e.changedTouches) return e.changedTouches[0].clientX;
    else return e.clientX;
}

export function getY(e) {
    if (e.changedTouches) return e.changedTouches[0].clientY;
    else return e.clientY;
}

export function getType(e){
    let type;
    switch(e.type){
        case 'mousedown':
        case 'mouseup':
        case 'mousemove':
            type = 'mouse';
            break
        case 'touchstart':
        case 'touchend':
        case 'touchmove':
            type = 'touch';
            break
        case 'pointerdown':
        case 'pointerup':
        case 'pointermove':
            type = 'pointer';
            break
    }

    return type;
}