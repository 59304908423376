import React from 'react';

export default class CollectionsListingComponent extends React.Component {
    state = {
        artworks: [],
        page: 0,
        canLoad: true,
        currentlyHoveredArtworkId: 0,
        firstLoad: true,
        initialLoad: true,
        fromUrl: false,
        fromSearch: false
    };

    componentWillMount() {
        window.ChartwellBootstrap = {
            artworksRefresh: (data, isNew, firstLoad, page, fromSearch) => {this.setState({
                    page,
                    firstLoad,
                    initialLoad: isNew,
                    fromSearch,
                    artworks: isNew ? data : this.state.artworks.concat(data)
                })
            }
        }
    }

    componentWillUnmount() {
        window.ChartwellBootstrap = {};
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.scrollTo && this.state.initialLoad && !this.state.fromUrl && !this.state.fromSearch) {
            this.setState({fromUrl: true});
            requestAnimationFrame(() => {
                let object = document.querySelector(`#artwork-${this.props.scrollTo}`);
                if(object) {
                    object.scrollIntoView();
                }

            });
        }

        if(prevState.artworks !== this.state.artworks) {
            this.props.updateBricks(Boolean(this.state.initialLoad || this.state.firstLoad));
        }

    }
    onClick = (id, page) => {
        this.props.updateDeeplink(id, page);
    };

    createArtworkElement = (a, index) => {
        const imageUrl = a.image ? a.image : '/images/placeholder-chw-v2.jpg';
        const width = a.image ? a.width : 600;
        const height = a.image ? a.height : 400;
        let classes = 'artworks__single overlay-link';
        if(this.state.currentlyHoveredArtworkId === a.objectID) {
            classes += ' hover-show';
        } else if(this.state.currentlyHoveredArtworkId === 0) {
            classes += ' hover-show';
        } else {
            classes += ' hover-hide';
        }
        let url = a.url;
        let page = false;
        if(!this.state.fromSearch) {
            page = Math.floor(index / this.props.pageSize);
            url += '?fromUrl=' + encodeURIComponent(this.props.getFromUrl(a.objectID, page));
        }

        const paddingTop = 100 * height / width;

        return (<div key={a.objectID} className="artworks__single-wrap" id={`artwork-${a.objectID}`}>
            <div className="artworks__single-content">
                <a href={url} className={classes} onMouseEnter={e => {this.setState({currentlyHoveredArtworkId: a.objectID})}} onMouseLeave={e => {this.setState({currentlyHoveredArtworkId: 0})}} onClick={() => {this.onClick(a.objectID, page)}}>
                    <div className="artworks__single-inner">
                        <div className="artworks__single-image artworks__single-image" style={{paddingTop: `${paddingTop}%`}}>
                            <img src={imageUrl} title={a.title}/>
                        </div>
                        <div className="artworks__single-text">
                            {parseInt(a.on_display, 10) ? <p className="on-show medium small">
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7" cy="7" r="7" />
                                    </svg>
                                </span>
                                On show
                            </p>:null}
                            <p className="caption">{a.title}</p>
                            <p className="artist caption faded">{a.artists.join(', ')}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>);
    }

    render() {
        const layout = this.state.artworks.map((a, index) => {
            return this.createArtworkElement(a, index);
        });

        if(this.state.artworks.length === 0 && !this.state.firstLoad){
            return (<div className="artworks__no-results"><p>We’re sorry! We cannot find any matches for your search.</p></div>)
        }

        return (<React.Fragment>
            {layout}
            {!this.state.canLoad && (<div className="loading__wrap">
                <img className="loading__img" src="/images/loading_spinner.gif"/>
            </div>)}
        </React.Fragment>);
    }
}
