import {Controller} from "stimulus"
import Cookies from "js-cookie"

export default class NewsletterController extends Controller {

    static targets = ['wrap']
    window = window

    /**
     * If cookie isnt found, must be first time viewer
     * Opens modal after 3500ms (3.5 seconds)
     */
    connect() {
        if(!Cookies.get('newsletterView')){
            // const scope = this
            // // setTimeout(function(){
            // //     scope.openModal()
            // // }, 3500)
        }
    }

    /**
     * Displays modal + Mailchimp signup form
     * Disables scroll on html element
     * Sets first-time view cookie if not set
     */
    openModal() {
        if(!Cookies.get('newsletterView')) Cookies.set('newsletterView', 'yes', { expires: 365 })
        this.wrapTarget.classList.add('newsletter--is-open')
        document.querySelector('html').style.overflowY = 'hidden'
    }  

    /**
     * Hides modal
     * Enables scroll on html element
     */
    closeModal() {
        this.wrapTarget.classList.remove('newsletter--is-open')
        document.querySelector('html').style.overflowY = 'scroll'
    }
}