import {Controller} from "stimulus"
import Validator from "validator";

export default class ContactController extends Controller {

    static targets = ['fieldNameError', 'fieldEmailError', 'fieldPhoneError', 'fieldEnquiryError',
        'fieldName', 'fieldEmail', 'fieldPhone', 'fieldEnquiry']


    connect() {

    }

    formSubmit(e) {

        if (this.validateForm()) {

        } else {
            e.preventDefault();
            return false;
        }
    }

    validateForm() {

        this.fieldNameErrorTarget.innerHTML = "";
        this.fieldPhoneErrorTarget.innerHTML = "";
        this.fieldEmailErrorTarget.innerHTML = "";
        this.fieldEnquiryErrorTarget.innerHTML = "";

        let isValid = true;
        if (Validator.isEmpty(this.fieldNameTarget.value)) {
            this.fieldNameErrorTarget.innerHTML = "Name is required";
            isValid = false;
        }

        if (Validator.isEmpty(this.fieldEmailTarget.value)) {
            this.fieldEmailErrorTarget.innerHTML = "Email is required";
            isValid = false;
        }


        if (!Validator.isEmail(this.fieldEmailTarget.value)) {
            this.fieldEmailErrorTarget.innerHTML = "Must be a valid email address";
            isValid = false;
        }

        if (Validator.isEmpty(this.fieldPhoneTarget.value)) {
            this.fieldPhoneErrorTarget.innerHTML = "Phone is required";
            isValid = false;
        }

        if (Validator.isEmpty(this.fieldEnquiryTarget.value)) {
            this.fieldEnquiryErrorTarget.innerHTML = "Enquiry is required";
            isValid = false;
        }


        return isValid;
    }

}