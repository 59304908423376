import { NAV_SLIDE, LOGO } from "../components/menu/constants";
import Draw from "../components/Draw";
import "intersection-observer"

class Home {

    constructor() {
        this.slideNav = document.querySelector(NAV_SLIDE);
        this.logo = document.querySelector(LOGO);
        this.heroText = document.querySelector(".hero__inner");

        const typesOfSections = [];
        typesOfSections.push(document.querySelectorAll(".u--section-pink"));
        typesOfSections.push(document.querySelectorAll(".u--section-green"));
        typesOfSections.push(document.querySelectorAll(".u--section-blue"));
        typesOfSections.push(document.querySelectorAll(".u--section-yellow"));

        const parallaxElements = document.querySelectorAll("[parallax]");

        new Draw();

        this.constructMenuColorChange(typesOfSections);
        this.constructParallax(parallaxElements);

        if (!document.cookie.split(';').filter((item) => item.includes('session=true')).length) {
            // cookie session doesn't exist
            document.body.classList.add("transition");
            const logoImage = this.logo.getElementsByTagName("img")[0];
            logoImage.addEventListener("load", this.unblurAnimated); // animate show
            if (logoImage.complete) {
                this.unblurAnimated(); // animate show
            }
        }else{
            this.unblurImmediate(); // immediate show
        }
    }

    constructMenuColorChange = (types) => {
        const options = {
            rootMargin: "-50%",
            threshold: 0
          }

          types.forEach(sections => {
            sections.forEach(section => {
                const observer = new IntersectionObserver(this.changeMenuBackgroundColor, options);
                observer.observe(section);
            })
        });
    }

    constructParallax = (elements) => {
        window.addEventListener("scroll", e => {
            this.adjustParallaxElements(elements);
        });
    }

    changeMenuBackgroundColor = (entries) => {
        entries.forEach(entry => {
            const {color} = entry.target.dataset;

            if(entry.isIntersecting){
                this.slideNav.classList.add(`u--menu-bg-${color}`);
            }else{
                this.slideNav.classList.remove(`u--menu-bg-${color}`);
            }
        });
    }

    unblurAnimated = () => {
        requestAnimationFrame(() => {
            document.body.offsetHeight; // force reflow
            this.slideNav.addEventListener("animationend", this.removeFilter);
            this.heroText.addEventListener("animationend", this.removeTransitionClass);
            document.body.classList.remove("blur");
            document.getElementById("gradient-canvas").classList.add("hide");
        });

        const logoImage = this.logo.getElementsByTagName("img")[0];
        logoImage.removeEventListener("load", this.unblurAnimated);
    }

    removeFilter = (e) => {
        if(e.target === this.slideNav){
            this.slideNav.classList.remove("filter");
            this.slideNav.removeEventListener("animationend", this.removeFilter);
        }
    }

    removeTransitionClass = (e) => {
        if(e.target === this.heroText){
            document.body.classList.remove("transition");
            this.heroText.removeEventListener("animationend", this.removeFilter);
        }
    }

    unblurImmediate = () => {
        this.slideNav.classList.remove("filter");
        document.body.classList.remove("blur");
        document.getElementById("gradient-canvas").classList.add("hide-immediate");
    }

    adjustParallaxElements = (elements) => {
        const {innerHeight: wh} = window;
        elements.forEach(element => {
            let {speed} = element.dataset;
            if(!speed) speed = 100;
            const elementBounds = element.getBoundingClientRect();
            const distToElement = elementBounds.top + elementBounds.height * 0.5 - wh*0.5;
            const distPercentage = distToElement * speed / wh;
            const offset = distPercentage;
            element.style.transform = `translate3d(0,${offset}px,0)`;
        })
    }

}

export default Home;