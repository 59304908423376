import Ajax from './classes/Ajax';
import Home from './classes/Home';
import State from './classes/State';
import Links from './classes/Links';

class App {

    animScroll = 0;
    lastAjaxPromise;

    constructor() {

        this.changePage = this.changePage.bind(this);
        // this.read = this.read.bind(this);
        // this.write = this.write.bind(this);
        // this.scrollAF = this.scrollAF.bind(this);


        this.ajax = Ajax.instance();
        this.state = State.instance();
        // this.af = AF.instance();

        if(document.querySelectorAll(".page-home").length > 0) this.home = new Home();
        this.pageLinks = new Links({parent: '.ajax-content'});
        this.content = document.querySelector('.main-content');
        this.staticLinks = new Links({items: '.nav-list a, .nav-slide a, a.logo'});

        // this.state.onChange(this.changePage);

        this.contentContainer = document.querySelector('.ajax-content');

        this.collectionsContainer = document.querySelector('.ajax-content__collections');
        this.collectionsSingleContainer = document.querySelector('.ajax-content__collections-single');

        this.pageContentInner = document.querySelector('.page-content__inner');
        this.mainContainer = document.querySelector('.main-content');
        this.body = document.querySelector('body');

        this.bodyHeight = null;
        this.lastBodyHeight = null;

        // Add cookie to track session - homepage blur animation when doesn't exist/expired
        window.addEventListener("load", this.addCookie);
    }


    scrollAF() {
        // this.contentContainer.style.transform = `translateY(-${this.animScroll}px)`;
    }

    read() {
        const rect = this.contentContainer.getBoundingClientRect();
        const {height} = rect;
        const {innerHeight, pageYOffset: y} = window;

        this.lastBodyHeight = this.bodyHeight;
        this.bodyHeight = Math.max(height + 100, innerHeight);
        this.animScroll += (y - this.animScroll) * 0.5;
    }

    write() {
        if (this.lastBodyHeight === this.bodyHeight) return;
        this.body.style.height = `${this.bodyHeight}px`;
    }

    changePage(location, action) {
        const {pathname} = location;
        const {pathname: lastPathname} = this.state.lastLocation;
        const scope = this;

        if(location.hash) {
            return;
        }

        if(pathname.indexOf('collections') === -1) {
            window.location.href = pathname;
            return;
        }

        if (pathname === lastPathname) return;

        this.pageLinks.unbind();
        this.body.style.overflow = "hidden";
        document.querySelector('.transition-rear').removeAttribute('style');
        window.scroll(0, 0);
        this.contentContainer.classList.remove('loaded');
        // this.content.classList.add('main-content--hide');
        this.pageContentInner.classList.add('page-content__inner--loading');
        this.mainContainer.style.display = "none";
        this.collectionsContainer.classList.remove('ajax-content__collections--show');
        this.collectionsSingleContainer.classList.remove('ajax-content__collections-single--show');
    }

    hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    addCookie = () => {
        const now = new Date();
        const expireInMinutes = 30;
        now.setTime(now.getTime() + (expireInMinutes * 60 * 1000));
        document.cookie = `session=true;  expires=Fri, ${now.toUTCString()}; path=/;`;
    }

}

export default App;


// document.addEventListener('load', () => {
//     document.querySelector('.news__wrap-inner').style.width = document.querySel
// })
//     $('').css({width: $('.news__single').length * 380});
// });
