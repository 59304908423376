import {Controller} from "stimulus"

export default class SliderController extends Controller {

    static targets = ['slide', 'wrap', 'outer'];

    window = window;
    canMove = false;
    startX = 0;
    endX = 0;
    current = 0;
    totalWidth = 0;
    isAnimating = false;
    contentContainer;
    maxNeg = -1000;

    connect() {

        const scope = this;

        const margin = parseInt(getComputedStyle(scope.slideTarget).marginRight.replace(/[^-\d\.]/g, ''))
        scope.contentContainer = document.querySelector('.article__content');
        scope.totalWidth = (scope.slideTarget.offsetWidth + margin) * scope.slideTargets.length;
        scope.maxNeg = (scope.totalWidth - scope.contentContainer.offsetWidth) * -1;


        this.window.addEventListener('resize', scope.reCalc);


        this.window.addEventListener('mousedown', function (e) {
            const {clientX: x} = e;
            this.startX = x;
        })

        this.window.addEventListener('mouseUp', function (e) {
            const {clientX: x} = e;
            this.startX = -1;
        })

        this.wrapTarget.addEventListener('mousedown', () => {
            this.canMove = true;
        });

        this.wrapTarget.addEventListener('mouseup', () => {
            this.canMove = false;
        });

        this.window.addEventListener('mousemove', function (e) {
            e.preventDefault();
            if (scope.canMove) {
                const {clientX: x, clientY: y} = e;
                if (scope.startX < 0) return;
                const move = (x - startX);

                if(!scope.isAnimating) {
                    scope.isAnimating = true;
                    if (!isNaN(move)) {
                        scope.current += move * .8;
                        if(move > 0) {
                            scope.current += (scope.outerTarget.offsetWidth / 2);
                        } else {
                            scope.current -= (scope.outerTarget.offsetWidth /2);
                        }

                    }

                    scope.window.requestAnimationFrame(() => {
                        scope.slideTargets.forEach(o => {
                            o.style.transform = `translateX(${scope.current}px)`;
                        });

                        setTimeout(() => {
                            scope.isAnimating = false;
                        }, 400);
                    });



                }

            }
        })

        setTimeout(() => {
            scope.initialise();
        }, 2000);

    }

    initialise = () => {
        const scope = this;
        const margin = parseInt(getComputedStyle(scope.slideTarget).marginRight.replace(/[^-\d\.]/g, ''))
        let slideWdith = scope.slideTarget.offsetWidth - (margin *2);
        let containerWidth = scope.outerTarget.offsetWidth;
        scope.current = (containerWidth - slideWdith) / 2;
        scope.slideTargets.forEach(o => {
            o.style.transform = `translateX(-${scope.current }px)`;
        });
    }


    reCalc = () => {
        const scope = this;

        const margin = parseInt(getComputedStyle(scope.slideTarget).marginRight.replace(/[^-\d\.]/g, ''))

        scope.contentContainer = document.querySelector('.article__content');
        scope.totalWidth = (scope.slideTarget.offsetWidth + margin) * scope.slideTargets.length;
        scope.maxNeg = (scope.totalWidth - scope.contentContainer.offsetWidth) * -1;
    }
}