import { Controller } from "stimulus";
import algoliasearch from "algoliasearch";
import ReactDOM from "react-dom";
import CollectionsListingComponent from "./../react/collection-listing.component";
import React from "react";
import Ajax from "./../classes/Ajax";
import moment from "moment";
import { parse, stringify } from "qs";
import Bricks from "bricks.js";

const allowed_filters = ["all", "search", "recent"];
const DEFAULT_FILTER = "search";
const DEFAULT_SEARCH_TYPE = "basic";
export default class CollectionsController extends Controller {
    static targets = [
        "advancedSearch",
        "searchToggleButton",
        "search",
        "formSearchBasic",
        "loader",
        "totalResultCount",
        "tag",
        "artworksWrap",
        "standardSearch",
        "asTitle",
        "asArtist",
        "asProductionYear",
        "asAccessionYear",
        "asAccessionNumber",
        "asMedium",
        "asSubject",
        "asDepartment",
        "asClassification",
        "searchToggleButtonContainer",
        "relatedArtworksWrap",
        "searchIntroduction",
        "recentAquisitionsIntroduction",
        "advancedSearchField",
        // "searchBorder"
        // "yearFilter"
    ];

    client = algoliasearch("FOOHZI3TLD", "7e86461495dc1c70963918bf86bff4ac");
    // index = this.client.initIndex('local_artworks');
    window = window;
    ajax = new Ajax();
    canLoad = true;
    allLoaded = false;
    page = 0;
    pageSize = 42;
    currentFilter = DEFAULT_FILTER;
    searchType = "basic";
    pageLinks;
    initialLoad = true;
    scrollToID = false;

    connect() {
        this.index = this.client.initIndex(this.data.get("index"));
        this.restoreStateFromUrl();

        this.window.addEventListener("scroll", this.handlePageScroll);
        this.window.addEventListener("resize", this.handleResize);
        this.window.addEventListener("popstate", this.onUrlChange);
        this.selectElements = document.querySelectorAll("select");
        this.selectElements.forEach((element) => {
            element.addEventListener("change", this.checkForPlaceholder);
        });
        this.sortElement = document.querySelector("#artworks-sort");
        this.sortElement.value = this.index.indexName;
        this.sortElement.addEventListener("change", (event) =>
            this.sort(event)
        );

        ReactDOM.render(
            <CollectionsListingComponent
                data
                scrollTo={this.scrollToID}
                updateBricks={this.updateBricks}
                getFromUrl={this.getFromUrl}
                pageSize={this.pageSize}
                updateDeeplink={this.updateUrlForDeepLink}
            />,
            document.getElementById("root")
        );
        this.loadArtworks(true, this.initialLoad);

        this.bricksContainer = document.getElementById("root");
        this.instantiateBricks(this.bricksContainer);

        this.ww = window.innerWidth;
    }
    link(e) {
        e.preventDefault();
        let href = e.currentTarget.getAttribute("href");
        window.history.pushState(null, null, href);
        this.restoreStateFromUrl(true);
        this.loadArtworks(true, false);
    }
    updateUrlForDeepLink = (id, page) => {
        this.scrollToID = id;
        this.updateUrl(false, true, page);
    };

    disconnect() {
        this.window.removeEventListener("scroll", this.handlePageScroll);
        this.window.removeEventListener("popstate", this.onUrlChange);
        this.window.removeEventListener("resize", this.handleResize);
        this.selectElements.forEach((element) => {
            element.removeEventListener("change", this.checkForPlaceholder);
        });
    }
    onUrlChange = () => {
        this.restoreStateFromUrl();
        this.loadArtworks(true, true);
    };
    updateUrl(clearId = false, replace = false, page = false) {
        let params = this.buildQueryParams(clearId);
        if (page !== false) {
            params.page = page;
        }
        let stringParams = stringify(params);
        window.history[replace ? "replaceState" : "pushState"](
            null,
            null,
            `${window.location.pathname}?${stringParams}`
        );
    }
    buildQueryParams(clearId = false) {
        let params = { filter: this.currentFilter, sort: this.index.indexName };
        if (this.currentFilter === "search") {
            params.searchType = this.searchType;
            if (this.searchType === "basic") {
                params.search = this.standardSearchTarget.value;
            } else {
                this.advancedSearchFieldTargets.forEach((field) => {
                    let value = field.value;
                    let name = field.getAttribute("name");
                    if (value) {
                        params[name] = value;
                    }
                });
            }
        }
        params.page = this.page;
        if (this.scrollToID && !clearId) {
            params.id = this.scrollToID;
        }
        return params;
    }
    restoreStateFromUrl(initial = true) {
        let queryParams = parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        this.searchType = queryParams.searchType
            ? queryParams.searchType
            : DEFAULT_SEARCH_TYPE;
        this.currentFilter = queryParams.filter
            ? queryParams.filter
            : DEFAULT_FILTER;
        this.standardSearchTarget.value = queryParams.search
            ? queryParams.search
            : "";
        this.advancedSearchFieldTargets.forEach((field) => {
            let name = field.getAttribute("name");
            let value = queryParams[name];
            if (value) {
                field.value = value;
            } else {
                field.value = "";
            }
        });
        this.index = queryParams.sort
            ? this.client.initIndex(queryParams.sort)
            : this.client.initIndex(this.data.get("index"));
        this.filter(
            { target: { dataset: { filter: this.currentFilter } } },
            initial
        );
        if (this.currentFilter === "search" && this.searchType !== "basic") {
            this.toggleAdvancedSearch({});
        } else {
            this.hideAdvancedSearch();
        }
        this.page = queryParams.page ? parseInt(queryParams.page, 10) : 0;
        if (queryParams.id) {
            this.scrollToID = parseInt(queryParams.id, 10);
        }
    }

    getFromUrl = (id, page) => {
        let params = this.buildQueryParams();
        params.id = id;
        if (page) {
            params.page = page;
        }
        return stringify(params);
    };

    filter(event, initial = false) {
        const scope = this;
        const element = event.target;

        const hasChanged = this.currentFilter !== element.dataset.filter;
        this.currentFilter = element.dataset.filter;
        if (this.currentFilter === "search") {
            const isCollapsed =
                this.searchTarget.getAttribute("data-collapsed") === "true";
            this.searchToggleButtonContainerTarget.style.display = "block";
            if (isCollapsed) {
                this.expandSection(this.searchTarget);
                this.standardSearchTarget.focus();
            } else {
            }
        } else {
            this.searchToggleButtonContainerTarget.style.display = "none";
            this.collapseSection(this.searchTarget);
            this.standardSearchTarget.value = "";
            this.hideAdvancedSearch();
            this.standardSearchTarget.blur();
        }

        this.tagTargets.forEach((e) => {
            if (e.dataset.filter === scope.currentFilter) {
                e.classList.add("active");
            } else {
                e.classList.remove("active");
            }
        });

        this.artworksWrapTarget.style.display = "block";

        if (this.currentFilter === "recent") {
            this.recentAquisitionsIntroductionTarget.style.display = "block";
            this.searchIntroductionTarget.style.display = "none";
        } else {
            this.recentAquisitionsIntroductionTarget.style.display = "none";
            this.searchIntroductionTarget.style.display = "block";
        }

        if (!initial) {
            this.page = 0;
            this.allLoaded = false;
            this.loadArtworks(true);
        }
    }

    sort(event) {
        const element = event.target;

        const hasChanged = this.index.indexName !== element.value;
        if (hasChanged) {
            this.index = this.client.initIndex(element.value);
        }
        // this.searchType = "advanced";
        this.updateUrl(true);
        this.loadArtworks(true);
    }

    /**
     * Load artworks from Algolia based on filters
     * @param clearList
     * @param initialLoad
     * @param offset
     * @param length
     */
    loadArtworks(
        clearList,
        initialLoad = false,
        offset = false,
        remainingRequired = false,
        results = false
    ) {
        const scope = this;
        let remainingLength;
        let remainingOffset;
        // Reset page if clearing list
        if (clearList && !initialLoad) {
            this.page = 0;
            this.allLoaded = false;
        }

        // Prevent multiple calls to search index
        if (this.canLoad && !this.allLoaded) {
            this.canLoad = false;

            let options = {
                query: "",
                page: this.page,
                hitsPerPage: this.pageSize,
            };

            if (initialLoad && this.page !== 0) {
                delete options.page;
                delete options.hitsPerPage;
                options.offset = offset !== false ? offset : 0;
                let requiredResults =
                    remainingRequired !== false
                        ? remainingRequired
                        : this.pageSize * (this.page + 1);
                options.length = Math.min(requiredResults, 1000);
                remainingLength = Math.max(0, requiredResults - 1000);
                remainingOffset = options.offset + options.length;
            }

            if (this.currentFilter === "recent") {
                options.filters = `accession_timestamp: ${moment()
                    .subtract(18, "months")
                    .unix()} TO ${moment().unix()}`;
            }

            if (this.currentFilter === "search") {
                if (this.searchType === "basic") {
                    options.query = this.standardSearchTarget.value;
                }

                if (this.searchType === "advanced") {
                    let query = this.buildAdvancedQueryString();
                    options.query = query.search;
                    options.restrictSearchableAttributes = query.fields;
                    options.disableTypoToleranceOnAttributes = query.fields;
                }
            }
            if (!initialLoad) {
                this.updateUrl(!initialLoad, true);
            }
            this.index.search(options, (err, content) => {
                if (err) {
                    // TODO: Handle errors gracefully
                    console.error(err);
                    return;
                } else {
                    this.totalResultCountTarget.innerHTML =
                        scope.getSearchResultText(content.nbHits);
                    if (
                        initialLoad &&
                        remainingLength > 0 &&
                        content.hits.length > 0
                    ) {
                        this.canLoad = true;
                        let totalResults =
                            results !== false
                                ? results.concat(content.hits)
                                : content.hits;
                        this.loadArtworks(
                            clearList,
                            true,
                            remainingOffset,
                            remainingLength,
                            totalResults
                        );
                        return;
                    } else if (results !== false) {
                        window.ChartwellBootstrap.artworksRefresh(
                            results.concat(content.hits),
                            clearList,
                            initialLoad,
                            this.page,
                            !!options.query
                        );
                    } else {
                        window.ChartwellBootstrap.artworksRefresh(
                            content.hits,
                            clearList,
                            initialLoad,
                            this.page,
                            !!options.query
                        );
                    }

                    if (this.pageSize * (this.page + 1) >= content.nbHits) {
                        this.allLoaded = true;
                    }

                    this.page++;
                }
                this.canLoad = true;
                this.initialLoad = false;
            });
        }
    }

    requestArtworks(options) {
        return new Promise((resolve, reject) => {
            this.index.search(options, (err, content) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(content);
                }
            });
        });
    }

    /**
     * Bricks.js instantiation and updating
     */
    instantiateBricks = (container) => {
        this.bricksInstance = Bricks({
            container: container,
            packed: "packed",
            sizes: [
                { columns: 1, gutter: 0 },
                { mq: "601px", columns: 2, gutter: 0 },
                { mq: "768px", columns: 3, gutter: 0 },
            ],
        });
    };

    updateBricks = (newLoad) => {
        if (newLoad) {
            this.bricksInstance.pack();
        } else {
            this.bricksInstance.update();
        }
    };

    /**
     * Get the formatted text for the search results
     * @param total
     * @returns {string}
     */
    getSearchResultText(total) {
        switch (this.currentFilter) {
            case "search": {
                return this.searchType === "basic" &&
                    this.standardSearchTarget.value.length > 0
                    ? `Found ${total} results found for '${this.standardSearchTarget.value}'`
                    : `Found ${total} artworks`;
            }
            case "recent": {
                return "";
            }
            default: {
                return `Found ${total} artworks`;
            }
        }
    }

    /**
     * Handle the submit of using the basic search form
     * @param e
     */
    basicSearchSubmit(e) {
        e.preventDefault();
        this.searchType = "basic";
        this.updateUrl(true);
        this.loadArtworks(true);
    }

    /**
     * Handle the submit action of the advanced form
     * @param e
     */
    advancedSearchSubmit(e) {
        e.preventDefault();
        this.searchType = "advanced";
        this.updateUrl(true);
        this.loadArtworks(true);
    }

    /**
     * Move scroll position to top of search
     */
    scrollToSearch = () => {
        // const { innerWidth: ww } = window;
        // const searchTop = document
        //     .querySelector(".artworks")
        //     .getBoundingClientRect().top;
        // const nav = document.querySelector(".nav-slide");
        // const navHeight = ww === nav.scrollWidth ? nav.scrollHeight : 0; // if menu is at the top of page, get the height of the menu, otherwise 0
        // const currentScrollTop = window.pageYOffset;
        // const scrollTopPos = currentScrollTop + searchTop - navHeight - 20;
        // window.scrollTo({
        //     top: scrollTopPos,
        //     behavior: "smooth",
        // });
    };

    /**
     * Check if the page is at the bottom
     */
    handlePageScroll = () => {
        if (
            this.window.innerHeight + this.window.pageYOffset + 1000 >=
            document.body.offsetHeight
        ) {
            this.loadArtworks(false);
        }
    };

    handleHashChange = () => {
        let urlState = window.location.hash
            ? window.location.hash.substr(1)
            : false;
        if (urlState && allowed_filters.indexOf(urlState) !== -1) {
            this.currentFilter = urlState;
            this.filter(
                { target: { dataset: { filter: this.currentFilter } } },
                true
            );
        }
    };
    /**
     * Adjust height of advanced search if it is expanded
     */
    handleResize = () => {
        this.adjustSection(this.advancedSearchTarget);

        // adjust packing of Bricks if window width changes
        const { innerWidth: newWidth } = window;

        if (this.ww !== newWidth) {
            this.ww = newWidth;
            this.bricksContainer.removeAttribute("style");
            this.bricksInstance.pack();
        }
    };

    /**
     * Go back to the top of the page
     */
    backToTop() {
        // TODO: Should animate this
        window.scrollTo(0, 0);
    }

    /**
     * Hide / show the advanced search fields
     */
    toggleAdvancedSearch(e) {
        const isCollapsed =
            this.advancedSearchTarget.getAttribute("data-collapsed") === "true";

        if (isCollapsed) {
            this.expandSection(this.advancedSearchTarget, true);
        } else {
            this.collapseSection(this.advancedSearchTarget, true);
        }

        this.formSearchBasicTarget.classList.toggle("form__search--hide");

        this.expandSection(this.searchTarget);
    }

    hideAdvancedSearch() {
        const isCollapsed =
            this.advancedSearchTarget.getAttribute("data-collapsed") === "true";

        if (!isCollapsed) {
            this.collapseSection(this.advancedSearchTarget, true);
            this.formSearchBasicTarget.classList.remove("form__search--hide");
        }
    }

    /**
     * Close an element
     * @param element
     */
    collapseSection(element, isTransitioning = false) {
        let collapsed = element.getAttribute("data-collapsed") === "true";
        if (!collapsed) {
            if (isTransitioning) {
                const sectionHeight = element.scrollHeight;
                const elementTransition = element.style.transition;
                element.style.height = sectionHeight + "px";
                element.style.transition = elementTransition;
                requestAnimationFrame(function () {
                    element.style.height = "0px";
                });
            } else {
                element.style.display = "none";
            }
            element.setAttribute("data-collapsed", "true");
        }
    }

    /**
     * Open an element
     * @param element
     */
    expandSection(element, isTransitioning = false) {
        if (isTransitioning) {
            const sectionHeight = element.scrollHeight;
            element.style.height = sectionHeight + "px";
        } else {
            element.style.display = "block";
        }
        element.setAttribute("data-collapsed", "false");
    }

    /**
     * Adjust height of element, if open
     * @param element
     */
    adjustSection(element) {
        const isCollapsed = element.getAttribute("data-collapsed") === "true";
        if (!isCollapsed) {
            const sectionHeight = element.firstElementChild.scrollHeight;
            element.style.height = sectionHeight + "px";
            element.setAttribute("data-collapsed", "false");
        }
    }

    /**
     * Add/remove placeholder class when value === "" is used
     */
    checkForPlaceholder = (e) => {
        const { target } = e;

        if (target.value === "") {
            target.classList.add("placeholder");
        } else {
            target.classList.remove("placeholder");
        }
    };

    /**
     * Build the advanced search fields
     * @returns object
     */
    buildAdvancedQueryString() {
        let filters = [];
        let fields = [];

        this.advancedSearchFieldTargets.forEach((field) => {
            let value = field.value;
            let name = field.getAttribute("name");
            if (value) {
                filters.push(value);
                fields.push(name);
            }
        });

        return { search: filters.join(" "), fields: fields };
    }
}
