import State from './State';

class PageLinks{
    links = [];
    linkFn = [];
    container = null;
    items = null;

    constructor({ parent = false , items = false } ){
        this.clickFunction = this.clickFunction.bind(this);

        if(parent) this.container = document.querySelector(parent);
        if(items) this.items = items;


        this.state = State.instance();
    }

    bind(){
        this.links = [];
        if(this.container) this.links = this.links.concat( Array.from(this.container.querySelectorAll('a')) );
        if(this.items) this.links = this.links.concat( Array.from(document.querySelectorAll(this.items)) );

        this.linkFn = new Array( this.links.length );

        this.links.forEach( (link, i) =>{
            if(link.classList.contains('overlay-link')) {
                const fn = (e) => this.clickFunction(e, link);
                this.linkFn[i] = fn;
                link.addEventListener('click', fn);
            }
        })
    }

    unbind(){
        this.links.forEach( (link, i) =>{
            link.removeEventListener('click', this.linkFn[i]);
        });
    }

    clickFunction(e, link){
        e.preventDefault();
        const href = link.getAttribute('href');
        this.state.change({
            page:href
        })
    }

};


export default PageLinks;