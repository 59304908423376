// import ready from "helpers/ready";

import { ACTIVE_CLASS, NAV_TOP, NAV_SLIDE, HEADER_MENU, PAGE_SELECTOR, NAV_SLIDE_HEADING, FOOTER, ANIMATED_NAV_SLIDE_SECTIONS, JS_LINK_ACTIVE } from "./constants";

class Menu{

	constructor(){
		this.menu = document.querySelector(HEADER_MENU);
		this.page = document.querySelector(PAGE_SELECTOR);
		this.topnav = document.querySelector(NAV_TOP);
		this.slideNav = document.querySelector(NAV_SLIDE);
		this.slideHeadings = document.querySelectorAll(NAV_SLIDE_HEADING);
        this.closeMenuLinks = document.querySelectorAll(JS_LINK_ACTIVE);
		this.footer = document.querySelector(FOOTER);
		this.animatedNavSections = document.querySelectorAll(ANIMATED_NAV_SLIDE_SECTIONS);

		window.addEventListener("resize", this.handleResize);

		this.menu.addEventListener("click", event => {
			event.preventDefault();
			this.navSlideToggle();
		});

		this.slideHeadings.forEach(slideHeading => {
			slideHeading.addEventListener("click", event => {
				const target = event.target;
				if(window.innerWidth <= 850){
					this.navSlideSubNavToggle(target);
				}
			});
		});
		if(this.closeMenuLinks.length) {
		    this.closeMenuLinks.forEach(link => {
		        let href = link.getAttribute('href');
		        let hrefParts = href.split('#');
		        let matchesPath = window.location.pathname === hrefParts[0];
                let matchesHash = hrefParts[1] ? window.location.hash.substr(1) === hrefParts[1] : window.location.hash === '';
		        if(matchesPath && matchesHash) {
		            link.classList.add('active');
                }
		        if(matchesPath) {
                    link.addEventListener('click', () => {
                        this.page.classList.toggle(ACTIVE_CLASS);
                        this.topnav.classList.toggle(ACTIVE_CLASS);
                        this.slideNav.classList.toggle(ACTIVE_CLASS);
                        document.querySelectorAll(JS_LINK_ACTIVE + '.active').forEach(link => link.classList.remove('active'));
                        link.classList.add('active');
                    })
                }

            })
        }
	}

	navSlideToggle = () => {
		this.page.classList.toggle(ACTIVE_CLASS);
		this.topnav.classList.toggle(ACTIVE_CLASS);
		this.slideNav.classList.toggle(ACTIVE_CLASS);

		const scrollTop = window.pageYOffset;
		const footerTopOld = this.footer.getBoundingClientRect().top;

		document.body.classList.toggle("lock");
		if(document.body.classList.contains("lock")){
			this.page.style.transform = `translateY(${-1*scrollTop}px)`;
			this.footer.style.top = `${footerTopOld}px`;
		}else{
			const scrollTopOld = parseInt(this.page.style.transform.replace("translateY(", "").replace("px)", ""));
			this.page.removeAttribute("style");
			this.footer.removeAttribute("style");
			document.documentElement.scrollTop = -1*scrollTopOld // other browsers
			if(scrollTop != scrollTopOld) document.body.scrollTop = -1*scrollTopOld // safari
		}
	}


	navSlideSubNavToggle = (target) => {
		const subNavParent = target.parentNode;
		const subNav = subNavParent.querySelector("ul");

		const innerHeight = subNav.scrollHeight;

		const wasActive = subNavParent.classList.contains("active");
		const {color} = subNavParent.dataset;

		this.navSlideSubNavCondenseAll();

		if(!wasActive){
			subNavParent.classList.add("active");
			subNav.style.height = `${innerHeight}px`;
			this.slideNav.classList.add(`u--menu-bg-${color}`);
		}
	}

	navSlideSubNavCondenseAll = () => {
		this.slideHeadings.forEach(slideHeading => {
			const subNavParent = slideHeading.parentNode;
			const subNav = subNavParent.querySelector("ul");
			subNavParent.classList.remove("active");
			subNav.style.height = 0;
		});

		const classList = this.slideNav.classList;
		for(let j = classList.length - 1; j > 0; j--){
			if(classList[j].startsWith("u--menu-bg-")){
				classList.remove(classList[j]);
			}
		}
	}

	navSlideReset = () => {
		this.slideHeadings.forEach(slideHeading => {
			const subNavParent = slideHeading.parentNode;
			const subNav = subNavParent.querySelector("ul");
			subNavParent.classList.remove("active");
			subNav.removeAttribute("style");
		});

		const classList = this.slideNav.classList;
		for(let j = classList.length - 1; j > 0; j--){
			if(classList[j].startsWith("u--menu-bg-")){
				classList.remove(classList[j]);
			}
		}
	}

	handleResize = () => {
		this.navSlideReset();

		this.animatedNavSections.forEach(section => {
			section.style.transition = "none";
			requestAnimationFrame(() => {
				section.offsetHeight; // force reflow
				section.removeAttribute("style");
			});
		});
	}

}

export default Menu;
