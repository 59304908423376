import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

class Gallery{

    constructor(){
        const gallerySelector = ".artwork-single__wrap__images";

        if(document.querySelectorAll(gallerySelector).length > 0){
            this.initPhotoSwipeFromDOM(gallerySelector);
        }
    };

    initPhotoSwipeFromDOM = (gallerySelector) => {

        const getOptions = (items) => {
            const options = {
                // define gallery index (for URL)
                history: false,
                getThumbBoundsFn: (index) => {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    const thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect(); 

                    return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                },
                bgOpacity: 0.95,
                showHideOpacity: true
            }

            return options;
        };

        const parseThumbnailElements = (el) => {
            let thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                width,
                height,
                item;
    
            for(let i = 0; i < numNodes; i++) {
    
                figureEl = thumbElements[i]; // <figure> element
    
                // include only element nodes 
                if(figureEl.nodeType !== 1) {
                    continue;
                }
    
                linkEl = figureEl.children[0]; // <a> element
    
                width = linkEl.getAttribute('data-width');
                height = linkEl.getAttribute('data-height');
    
                // create slide object
                item = {
                    src: linkEl.getAttribute('href'),
                    w: parseInt(width),
                    h: parseInt(height)
                };
    
                if(figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML; 
                }
    
                if(linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute('src');
                } 
    
                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }
    
            return items;
        };
    
        // find nearest parent element
        const closest = (el, fn) => {
            return el && ( fn(el) ? el : closest(el.parentNode, fn) );
        };
    
        // triggers when user clicks on thumbnail
        const onThumbnailsClick = (e) => {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;
    
            const eTarget = e.target || e.srcElement;
    
            // find root element of slide
            const clickedListItem = closest(eTarget, (el) => {
                return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
            });
    
            if(!clickedListItem) {
                return;
            }
    
            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            let clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;
    
            for (let i = 0; i < numChildNodes; i++) {
                if(childNodes[i].nodeType !== 1) { 
                    continue; 
                }
    
                if(childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }
    
            if(index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe( index, clickedGallery );
            }
            return false;
        };
    
        const openPhotoSwipe = (index, galleryElement, disableAnimation, fromURL) => {

            let pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;
    
            items = parseThumbnailElements(galleryElement);
    
            options = getOptions(items);
    
            // PhotoSwipe opened from URL
            if(fromURL) {
                if(options.galleryPIDs) {
                    // parse real index when custom PIDs are used 
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for(let j = 0; j < items.length; j++) {
                        if(items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }
    
            // exit if index not found
            if( isNaN(options.index) ) {
                return;
            }
    
            if(disableAnimation) {
                options.showAnimationDuration = 0;
            }
    
            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        const openPhotoSwipeFromButton = () => {
            galleryElements[0].click();
        }
    
        // loop through all gallery elements and bind events
        const galleryElements = document.querySelectorAll( gallerySelector );
    
        for(let i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i+1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        const openGalleryButton = document.querySelector("open-gallery");
        if(openGalleryButton) openGalleryButton.addEventListener("click", openPhotoSwipeFromButton);
    };


};

export default Gallery;