import AF from './../../utils/AF';
import bezier from './../../helpers/bezier-easing';

export default class Animation {
    type = 'drag';
    x = 0;
    xAnim = 0;
    stopped = true;

    _drag = {
        step: 0.12,
    };

    _release = {
        progres: 0,
        duration: 1000,
        timeStart: null,
        xStart: 0,
        distance: 0,
        ease: bezier(0.135, 0.435, 0.12, 0.975),
    };

    constructor(el) {
        this.el = el;
        this._write = this._write.bind(this);
        this._render = this._render.bind(this);
        this.af = AF.instance();
    }

    drag(x) {
        this.type = 'drag';
        this.x = x;
        this._start();
    }

    release(x) {
        this.af.onNextWrite(() => {
            let { _release: r } = this;
            this.type = 'release';
            this.x = x;
            r.progress = 0;
            r.timeStart = window.performance.now();
            r.xStart = this.xAnim;
            r.distance = this.x - this.xAnim;
            this._start();
        });
    }

    jump(x) {
        this.af.onNextWrite(() => {
            this.x = x;
            this.xAnim = x;
            this._render();
        });
    }

    offset(x) {
        this.af.onNextWrite(() => {
            this.xAnim = this.xAnim - x;
        });
    }

    _write() {
        this._tick();
        this._render();
    }

    _render() {
        // console.log('_render');
        this.el.style.transform = 'translateX(' + this.xAnim + 'px)';
    }

    _tick() {
        if (Math.abs(this.x - this.xAnim) < 1) {
            this._stop();
            return;
        }

        switch(this.type) {
            case 'drag':
                this.xAnim += (this.x - this.xAnim) * this._drag.step;
                break;
            case 'release':
                let { _release: r } = this;
                if (r.progress < r.duration) {
                    r.progress = Math.min(window.performance.now() - r.timeStart, r.duration);
                    this.xAnim = r.xStart + (r.distance * r.ease(r.progress/r.duration));
                } else {
                    this._stop();
                }
                break;
        }
    }

    _start() {
        if(!this.stopped) return;
        this.stopped = false;
        this.af.addWrite(this._write);
    }

    _stop() {
        if(this.stopped) return;
        this.stopped = true;
        this.af.removeWrite(this._write);
    }
}