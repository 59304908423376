import Bricks from 'bricks.js';

export default class ArtworksLayout{

    constructor(){
        this.bricksContainer = document.getElementById('artworks-layout');
        if(this.bricksContainer) {
            this.instantiateBricks(this.bricksContainer);
            window.addEventListener('resize', this.handleResize);
            window.addEventListener('load', this.handleLoad);
        }

        this.ww = window.innerWidth;
    }

    instantiateBricks = (container) => {
        this.bricksInstance = Bricks({
            container: container,
            packed: "packed",
            sizes: [
                {columns: 1, gutter: 0},
                {mq: "601px", columns: 2, gutter: 0},
                {mq: "768px", columns: 3, gutter: 0}
            ]
        });

        if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
            this.handleLoad();
        }
    }

    handleLoad = () => {
        this.bricksInstance.pack();
    }

    handleResize = () => {
        // adjust packing of Bricks
        const {innerWidth: newWidth} = window;

        if(this.ww !== newWidth){
            this.ww = newWidth;
            this.bricksContainer.removeAttribute("style");
            this.bricksInstance.pack();
        }
    }

}
