import OpSlider from './opSlider';

const SELECTORS = {
    slider: '[op-slider]',
    frame: '[op-slider-frame]',
    list: '[op-slider-list]',
    slide: '[op-slider-slide]',
    dots: '[op-slider-dots]',
    left: '[op-slider-left]',
    right: '[op-slider-right]',
};

export function init() {
    const sliderNodes = [].slice.call(document.querySelectorAll(SELECTORS.slider));

    if (sliderNodes.length > 0) {

        let sliders = sliderNodes.map((sliderNode) => {
            const config = {
                slider: sliderNode,
                frame: sliderNode.querySelector(SELECTORS.frame),
                list: sliderNode.querySelector(SELECTORS.list),
                slides: [].slice.call(sliderNode.querySelectorAll(SELECTORS.slide)),
                dots: sliderNode.querySelector(SELECTORS.dots),
                prevItems: [
                    sliderNode.querySelector(SELECTORS.left)
                ],
                nextItems: [
                    sliderNode.querySelector(SELECTORS.right)
                ],
                mode: {
                    centered: false,
                    fill: true,
                    infinite: false,
                    multiple: true,
                    updateDotsOnDrag: false,
                },
            };
            return new OpSlider(config);
        });
    }
}
